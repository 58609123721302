import {
    CREATE_SCHELUDE_CAMPPAIGN,
    DELETE_RESPONSE
}
    from '../types';


// cada reducer tiene su propio state

const initialState = {
    error: null,
    loading: false,
    delete_response: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CREATE_SCHELUDE_CAMPPAIGN:
            return {
                ...state,
                loading: true,
                plantillas: []
            }
        case DELETE_RESPONSE:
            return {
                ...state,
                delete_response: action.payload
            }
        default:
            return state;
    }
}