import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { set_session_with_params } from '../../actions/authActions';
import clienteAxios from '../../config/axios'
import { useSelector, useDispatch } from 'react-redux';
import { LoaderSpinner } from '../../components/LoaderSpinner';

export const WithImpulsa = () => {
    let history = useHistory();
    const dispatch = useDispatch()
    const [loading, setloading] = useState(false);
    const [cuentas, setCuentas] = useState();

    const obtenerCuentas = async () => {
        try {

            const response = await clienteAxios.post('/auth/with_impulsa');
            setCuentas(response.data);

        } catch (error) {
            console.log(error);
        }
    }
    useState(() => {
        obtenerCuentas();
    }, [])

    const setThisAsUserLogged = async (user_id) => {
        setloading(true);
        const response = await clienteAxios.post('/auth/set_as_admin', { user_id });
        setloading(false);

        dispatch(set_session_with_params(response.data.userObject))
        localStorage.setItem('lasttoken', clienteAxios.defaults.headers['x-access']);
        clienteAxios.defaults.headers['x-access'] = response.data.token

        history.push("/home");
    }

    return (
        <div className="white_box QA_section card_height_100 p-4">
            {
                loading ? <LoaderSpinner /> : null
            }
            <span className="mr-3 naranja"> Seleccione una cuenta</span>
            <Link to="/admin_accounts" className="btn btn-link float-right" > Volver</Link>
            <div className="table-responsive">

                <table className="table">
                    <thead>
                        <th>Nombre</th>
                        <th>Email</th>
                        <th>Nombre Empresa</th>
                        <th>Nombre Cuenta</th>
                        <th>Estadi</th>
                        <th>Rol</th>
                        <th>Database</th>
                        <th></th>
                    </thead>
                    <tbody>
                        {
                            cuentas && cuentas.length > 0 && cuentas.map(cuenta => (
                                <tr>
                                    <td>{cuenta.nombre}</td>
                                    <td>{cuenta.email}</td>
                                    <td>{cuenta.nombre_empresa}</td>
                                    <td>{cuenta.nombre_cuenta}</td>
                                    <td>{cuenta.isActive ? <div className="badge badge-success">Activo</div> : <div className="badge badge-danger">Inactivo</div>}</td>
                                    <td>{cuenta.rol}</td>
                                    <td><div className="badge badge-secondary">{cuenta.database}</div></td>
                                    <td>
                                        <button style={{fontSize:"12px"}} className="btn btn-primary" onClick={() => setThisAsUserLogged(cuenta.id)}>Ingresar</button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

        </div>
    )
}
