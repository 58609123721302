import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { LoaderSpinner } from '../../components/LoaderSpinner';
import Modal from 'react-bootstrap4-modal';

import { obtenerCampaign, getElementosAlista, sendCampaign, sendTestEmail } from '../../actions/campaignsActions';

import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import $ from 'jquery'
import { BreadCrumbCampaign } from '../../components/BreadCrumbCampaign';
import { create_scheduledDate_action } from '../../actions/scheduleCampaignActions';
import moment from 'moment';
import { statusObject } from '../mailMarketing/MailMarketing';

export const View = () => {

    const dispatch = useDispatch()

    const { idCampaign } = useParams()
    const [modalVisible, setModalVisible] = useState(false);
    const [modalScheludeVisible, setModalScheludeVisible] = useState(false);
    const [testEmails, setTestEmails] = useState("");

    const campaignedit = useSelector(state => state.campaigns.campaignedit)
    const campaignList = useSelector(state => state.campaigns.campaignList)

    const search_custom_page = useSelector(state => state.landings.search_custom_page)
    const [scheduledDate, setScheduledDate] = useState("");  // Nueva variable para la fecha y hora de programación

    const [htmltoCamp, setHtmlToCamp] = useState('');
    const loading = useSelector(state => state.campaigns.loading)
    const [designtype, setDesigntype] = useState('');
    const cerrarVentana = () => {
        setModalVisible(false);
    }
    const cerrarScheludeVentana = () => {
        setModalScheludeVisible(false);
    }
    const enviarProgramacionDeEnvio = async () => {
        // Validar que la fecha y hora seleccionadas no sean anteriores al momento actual
        if (new Date(scheduledDate) < new Date()) {
            Swal.fire({
                title: "Error",
                text: "La fecha y hora seleccionadas deben ser posteriores al momento actual.",
                icon: "error",
                confirmButtonText: "Cerrar"
            });
            return;
        }

        // Aquí puedes agregar la lógica para programar el envío del correo
        try {
            // let convertedTime = convertToUserZone(scheduledDate)
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            // console.log('Correo programado para:', scheduledDate);
            dispatch(create_scheduledDate_action(scheduledDate, idCampaign, timezone))

        } catch (error) {
            Swal.fire({ title: "Error", text: 'Ocurrió un error', icon: "error" })
        }
        // setModalScheludeVisible(false);
    }
    const convertToUserZone = (scheduledDate) => {
        // 1. Tomar la fecha/hora local del input
        const localDatetime = new Date(scheduledDate);

        // 2. Obtener la diferencia de minutos con UTC (si estás en UTC-4, esto da 240, etc.)
        const offset = localDatetime.getTimezoneOffset();

        // 3. Restar ese offset en minutos para obtener el tiempo en UTC
        //    OJO: getTimezoneOffset() regresa minutos, por eso multiplicamos * 60000 (milisegundos).
        const utcTime = new Date(localDatetime.getTime() - (offset * 60000));

        return utcTime.toISOString();

    }
    useEffect(async () => {
        await dispatch(obtenerCampaign(idCampaign));
        await dispatch(getElementosAlista(idCampaign));
        if (campaignedit && designtype == '' && campaignedit.contenido) {
            let content = JSON.parse(campaignedit.contenido)
            // if(JSON.parse(campaignedit.contenido).html){
            //     setHtmlToCamp(JSON.parse(campaignedit.contenido).html);
            //     setHtmlToCamp($(htmltoCamp).find("#image_to_track").html());
            // }
            if (typeof content.html != 'undefined') {
                setDesigntype('html')
            } else {
                setDesigntype('text')
            }
            // console.log('designtype', designtype)
        }
        if (search_custom_page == null) {
            $('#loading').hide();
        }

    }, [designtype, search_custom_page])


    const send = async (id) => {
        if (campaignedit && campaignedit.lista) {

            //console.log(campaignedit);
            if (campaignedit.sending >= 1) {
                let html = ``;
                Object.keys(campaignedit.lista).forEach(key => {
                    html += `Para evitar datos duplicados, no puede volver a enviar la campaña.`;
                });
                Swal.fire({
                    html,
                    title: 'La campaña ya fue enviada',
                    showDenyButton: true,
                    denyButtonText: 'OK',
                    showConfirmButton: false,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        // Swal.fire('Saved!', '', 'success')
                        //await dispatch(sendCampaign(id));
                    }
                });
            } else {
                let html = ``;
                Object.keys(campaignedit.lista).forEach(key => {
                    html += `Será enviado a <b>${campaignedit.lista[key]}</b> correos de la lista '<b>${key}</b>'`
                })
                Swal.fire({
                    html,
                    title: '¿Está seguro desea enviar este mail?',
                    showDenyButton: true,
                    confirmButtonText: 'Enviar',
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        // Swal.fire('Saved!', '', 'success')
                        await dispatch(sendCampaign(id))
                    }
                })
            }

        }

    }

    const sendtMail = (id) => {
        setModalVisible(true);

    }

    const programarEnvio = (id) => {
        setModalScheludeVisible(true);
    }

    const enviarEmailPrueba = async () => {
        await dispatch(sendTestEmail({
            testEmails,
            campaignid: campaignedit.id
        }))
    }

    const handleTestMails = (e) => {
        setTestEmails(e.target.value)
    }

    const componenteBorrador = () => {
        return <p className="list-group-item-heading">Estado: <span className="badge badge-secondary">Borrador</span> </p>;
    }

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <BreadCrumbCampaign active={5} />
                <div className="row">
                    <div className="col-md-6 col-xs-12">
                        <span className="mr-3 naranja">Vista de Campa&ntilde;a "{campaignedit && campaignedit.nombre}"</span>
                    </div>
                    <div className="col-md-6 col-xs-12 mt-2">

                        <Link to="/mail_marketing" className="btn btn-link float-right colorsito" >Volver</Link>
                        <button onClick={() => send(campaignedit.id)} className="btn btn-success float-right" >Enviar</button>
                        <button onClick={() => sendtMail(campaignedit.id)} className="btn btn-secondary float-right mr-2" >Enviar email de prueba</button>
                        {
                            campaignedit?.scheduledCampaign == null ?
                                <button
                                    title="Programar envío"
                                    onClick={() => programarEnvio(campaignedit.id)}
                                    className="btn btn-border float-right mr-2 programar-btn d-flex align-items-center justify-content-center"
                                >
                                    <i className="fas ml-2 fa-clock"></i>
                                    <span className="tooltip-text">Programar envío</span>
                                </button> : null
                        }

                    </div>
                    {
                        loading ? <LoaderSpinner /> : null
                    }
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-md-4">
                    <div className="panel panel-default custom-panel">
                        <div className="panel-body"></div>
                        <div className="list-group list-group-custom list-group-min-padding list-group-object-info">
                            {
                                campaignedit?.scheduledCampaign ?
                                    <div className="list-group-item">
                                        {
                                            campaignedit.scheduledCampaign?.status ?
                                                <>
                                                    <p className="list-group-item-heading">Programación de envío: {statusObject[campaignedit.scheduledCampaign.status]}</p>
                                                    <p className="list-group-item-text">Programado para: {moment.utc(campaignedit.scheduledCampaign.send_time).local().format("DD-MM-YYYY HH:mm:ss")}</p>
                                                </> :
                                                null
                                        }
                                        {
                                            campaignedit.scheduledCampaign && campaignedit.scheduledCampaign.event_name == "SUCCESS_SENDING_MAIL" ?
                                                <>
                                                    <p className="list-group-item-heading">Estado: <span className="badge badge-success">enviado</span> </p>
                                                    <p className="list-group-item-text">Enviado: {moment.utc(campaignedit.scheduledCampaign.createdAt).local().format("DD-MM-YYYY HH:mm:ss")}</p>
                                                </>
                                                : (campaignedit.scheduledCampaign?.status == 'undefined' || campaignedit.scheduledCampaign == null ? componenteBorrador() : null)
                                        }


                                    </div>
                                    : <div className="list-group-item">
                                        {componenteBorrador()}
                                    </div>
                            }
                            <div className="list-group-item">
                                <p className="list-group-item-heading">Creada por</p>
                                <p className="list-group-item-text">{campaignedit && campaignedit.creado?.nombre}</p>
                            </div>

                            <div className="list-group-item">
                                <p className="list-group-item-heading">Listas</p>
                                <p className="list-group-item-text">
                                    {
                                        campaignList && campaignList.arrList && campaignList.arrList.length && campaignList.arrList.map((l, index) => (
                                            l && <Link key={index} className="marketing-list-contacts ml-2" style={{ 'display': "block" }} to={`/view_maillist/${l.id}`}>{l.nombre}</Link>
                                        ))
                                    }

                                </p>
                            </div>

                            <div className="list-group-item">
                                <p className="list-group-item-heading">Tipo</p>
                                <p className="list-group-item-text">{designtype}</p>
                            </div>
                            <div className="list-group-item">
                                <p className="list-group-item-heading">Asunto</p>
                                <p className="list-group-item-text">{campaignedit && campaignedit.asunto}</p>
                            </div>
                            <div className="list-group-item">
                                <p className="list-group-item-heading">Nombre de remitente</p>
                                <p className="list-group-item-text">{campaignedit && campaignedit.remitente}</p>
                            </div>
                            <div className="list-group-item">
                                <p className="list-group-item-heading">Email remitente</p>
                                <p className="list-group-item-text">{campaignedit && campaignedit.email_remitente}</p>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-md-8">
                    {
                        campaignedit && campaignedit.contenido ?
                            <div id="myCustomContent" dangerouslySetInnerHTML={{ __html: JSON.parse(campaignedit.contenido).html ? JSON.parse(campaignedit.contenido).html : JSON.parse(campaignedit.contenido) }}></div>
                            : null
                    }
                </div>
            </div>
            <Modal visible={modalVisible} >
                <div className="modal-header">
                    <h5 className="modal-title">Introduzca la dirección de correo</h5>
                </div>
                <div className="modal-body">
                    <div className="form-group">
                        <label>Ingrese la/las direcciones de correo</label>
                        <input type="text" className="form-control" value={testEmails} onChange={handleTestMails} placeholder="email1@gmail.com, email2@gmail.com" />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={cerrarVentana} className="btn btn-default">
                        Cerrar
                    </button>
                    <button type="button" onClick={enviarEmailPrueba} className="btn btn-primary">
                        Enviar
                    </button>
                </div>
            </Modal>
            <Modal visible={modalScheludeVisible} >
                <div className="modal-header">
                    <h5 className="modal-title naranja">Programar Envio</h5>
                </div>
                <div className="modal-body">
                    <div className="form-group">
                        <label>Seleccionar fecha y hora de envío:</label>
                        <input
                            type="datetime-local"
                            className="form-control"
                            value={scheduledDate}
                            onChange={(e) => setScheduledDate(e.target.value)}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={() => cerrarScheludeVentana()} className="btn btn-default">
                        Cerrar
                    </button>
                    <button type="button" onClick={() => enviarProgramacionDeEnvio()} className="btn btn-primary">
                        Programar
                    </button>
                </div>
            </Modal>
        </div>
    )
}
