import React,{ useState, useEffect} from 'react'
import {FormSearch} from '../components/FormSearch'
import {
    Link,
    useHistory,
    useLocation
  } from "react-router-dom";
import { NavUser } from '../components/NavUser';
import { useSelector, useDispatch } from 'react-redux';
import { logout, verificarSesion, setCurrentMenu } from '../actions/authActions';
import { set_current_landing_page, obtenerLandingToShow, finishCustomPage, addConvertion, buscandoCustomPage, sendFormData } from '../actions/landingActions';


import Swal from 'sweetalert2';
import $ from 'jquery';
import clienteAxios from '../config/axios';

export const Navbar = () => {
    const d = new Date();
    let history = useHistory();
    const dispatch = useDispatch()
    const [busco,setBusco] = useState(false);
    const [currentUser,setCurrentUser] = useState({
        nombre:'',
        mail:'',
        telefono:''
    });


    const [ account_name, setAccountName ] = useState('')
    const [ slug, setSlug ] = useState('')

    const [mandoForm, setMandoForm] = useState(false);

    const forminfo = useSelector( state => state.landings.forminfo )
    const user = useSelector( state => state.user.user )
    // const loading = useSelector( state => state.user.loading )
    const isAuth = useSelector( state => state.auth.isAuthenticated )
    const current_menu = useSelector( state => state.auth.current_menu )
    const userAuth = useSelector( state => state.auth.user )
    const path = window.location.href;

    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    useEffect( async () => {
        if(forminfo && account_name){
            
            const favicon = document.getElementById("favicon");
            favicon.href = process.env.REACT_APP_BACKEND+`/lading_page/favicon/${account_name}/${slug}`;
            

            if(forminfo.meta_title){
                document.title = forminfo.meta_title;
            }
            let script_;
            if(forminfo.google_analitics){
     
                script_ = document.createElement("script");
                script_.innerHTML = forminfo.google_analitics;
                // script.src = forminfo.facebook_pixel;
            
                document.body.appendChild(script_);
            }
            if(forminfo.facebook_pixel){
                script_ = document.createElement("script");
                script_.innerHTML = forminfo.facebook_pixel;
                // script.src = forminfo.facebook_pixel;
            
                document.body.appendChild(script_);
            }
            if(forminfo.custom_script){
                script_ = document.createElement("script");
                script_.innerHTML = forminfo.custom_script;
                // script.src = forminfo.custom_script;
            
                document.body.appendChild(script_);
            }
            
        }
        if(busco === false){
            // await dispatch(verificarSesion());
            if(user && user.data){
                setCurrentUser(user.data);
            }
            // dispatch(obtenerUsuario(1));
            setBusco(true);
            
        }
        if(!isAuth && busco)
        {
            // history.push("/login"); 
            try {
                const result = await clienteAxios.post('/users/me');
                // console.log('result',result.data)
            } catch (error) {
                const landing = await checkCustomerLandingPage();
                // alert("?")
                if(landing.data.success){
                    
                    // console.log(landing.data.account_info.account_name,landing.data.account_info.slug)
                    if(landing.data.form && landing.data.form.ws){
                        let objectToEdit = JSON.parse(landing.data.landing.html);
                        objectToEdit.html = objectToEdit.html+`<a href="https://web.whatsapp.com/send?phone=${forminfo && forminfo.ws}" className="ws" target="_blank">
                            <img src="https://www.sistemaimpulsa.com/img/whatsapp.png" width="60" />
                           </a>`
                           landing.data.landing.html = JSON.stringify(objectToEdit);
                 
                    }
                    await dispatch(set_current_landing_page(landing.data.landing,landing.data.form,landing.data.account_info));
                    // console.log('forminfo',forminfo)
                    console.log('landing.data.landing',landing.data.landing)
                    let forminfolocal = landing.data.form;
                    if(forminfolocal){
                        const favicon = document.getElementById("favicon");
                        favicon.href = process.env.REACT_APP_BACKEND+`/lading_page/favicon/${landing.data.account_info.account_name}/${landing.data.account_info.slug}`;
                        
                        setAccountName(landing.data.account_info.account_name);
                        setSlug(landing.data.account_info.slug);

                        if(forminfolocal.meta_title){
                            document.title = forminfolocal.meta_title;
                        }
                        let script_;
                        if(forminfolocal.google_analitics){
                 
                            script_ = document.createElement("script");
                            script_.innerHTML = forminfolocal.google_analitics;
                            // script.src = forminfolocal.facebook_pixel;
                        
                            document.body.appendChild(script_);
                        }
                        if(forminfolocal.facebook_pixel){
                            script_ = document.createElement("script");
                            script_.innerHTML = forminfolocal.facebook_pixel;
                            // script.src = forminfolocal.facebook_pixel;
                        
                            document.body.appendChild(script_);
                        }
                        if(forminfolocal.custom_script){
                            script_ = document.createElement("script");
                            script_.innerHTML = forminfolocal.custom_script;
                            // script.src = forminfo.custom_script;
                        
                            document.body.appendChild(script_);
                        }
                        var forms = document.getElementsByTagName('form');

                        if(forms[0]){
                            // console.log('account_name',account_name)
                            // console.log('slug',slug)
                            forms[0].addEventListener('submit', async (e)=>{
                                e.preventDefault();
                                //obtiene el form para serializarlo
                                let form = document.querySelector('form');
                                
                                if(form && !mandoForm){
                                  
                                  setMandoForm(true);
                                  //lo serializa
                                  let object = getAllUrlParams(form);
                                  //agrega una conversion porque paso el form
                                  await dispatch(addConvertion(landing.data.account_info.account_name, landing.data.account_info.slug));
                                  // pasa los datos del formulario al submit
                                  await dispatch(sendFormData(object,{account_name:landing.data.account_info.account_name, slug:landing.data.account_info.slug}));
                                  
                                  //verifica la action despues que pasa el formulario si es mensaje
                                  if(forminfolocal && forminfolocal['action'] == 'mensaje'){
                                      //alerta de error
                        
                                      Swal.fire({
                                          icon:'success',
                                          title:'',
                                          text:forminfolocal.action_response
                                      })
                                      
                                  }
                                  //si la accion es algun tipo de redireccion
                                  if(forminfolocal && (forminfolocal['action'] == 'redireccionar_url' || forminfolocal['action'] == 'redireccionar_landing')){
                                      //alerta de error
                                      console.log('forminfolocal.action_response',forminfolocal.action_response)
                                      window.location.href = forminfolocal.action_response;
                                  }
                                }
                              });
                        }
                        
                    }
                    return null;

                }else{
                    history.push('/login'); 
                }
                
            }
            
        }
        if(userAuth){
            if(userAuth.roles[0].name != 'impulsa' && isImpulsaPath(path)){
                // comentar esto me resolvio el problema de las rutas que no podia entrar a editar y refresh la pagina
                // alert(path)
                const landing = await checkCustomerLandingPage();
                console.log('landing',landing)
                if(landing.data.success){
                    setAccountName(landing.data.account_info.account_name);
                    setSlug(landing.data.account_info.slug);
                    // console.log(account_name,slug)

                    dispatch(set_current_landing_page(landing.data.landing,landing.data.form,landing.data.account_info))
                    return null;

                }else{
                    history.push('/login'); 
                }
            }
        }

    },[user,isAuth,busco,forminfo])
    const getAllUrlParams = (form) => {
        if(form){
          
          return JSON.parse('{"' + decodeURI(new URLSearchParams(new FormData(form)).toString()).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
        }
      }
    const logSubmit = async (e) => {
        e.preventDefault();
        //obtiene el form para serializarlo
        let form = document.querySelector('form');
        
        if(form && !mandoForm){
          
          setMandoForm(true);
          //lo serializa
          let object = getAllUrlParams(form);
          //agrega una conversion porque paso el form
          await dispatch(addConvertion(account_name, slug));
          // pasa los datos del formulario al submit
          await dispatch(sendFormData(object,{account_name, slug}));
          
          //verifica la action despues que pasa el formulario si es mensaje
          if(forminfo && forminfo['action'] == 'mensaje'){
              //alerta de error

              Swal.fire({
                  icon:'success',
                  title:'',
                  text:forminfo.action_response
              })
              
          }
          //si la accion es algun tipo de redireccion
          if(forminfo && (forminfo['action'] == 'redireccionar_url' || forminfo['action'] == 'redireccionar_landing')){
              //alerta de error
              console.log('forminfo.action_response',forminfo.action_response)
              window.location.href = forminfo.action_response;
          }
        }
      }
    const checkCustomerLandingPage = async () => {
        dispatch(buscandoCustomPage())
        const page = await clienteAxios.get('/lading_page/customerlandingpage/'+window.location.hostname+"/"+JSON.stringify(window.location.href.split('/')));
        dispatch(finishCustomPage());
        return page;
    }

    const handleLogout = () => {
        console.log('logout')
        localStorage.setItem('lasttoken',null)
        dispatch(logout(history))
    }

    const isImpulsaPath = (path) => {
        const safe = ['admin_accounts','edit_user'];
        const pathSplited = path.split('/');
      
        for (let index = 0; index < safe.length; index++) {
            if(pathSplited.indexOf(safe[index]) != -1){
                return true;
                break;
            }
        }

        return false;
    }
    const setAsImpulsa = () => {
        clienteAxios.defaults.headers['x-access'] = localStorage.getItem('lasttoken')
        localStorage.setItem('lasttoken',null)
     
        history.push("/admin_accounts");

    }
    return (
        
                <nav className="sidebar">
                    <div className="logo d-flex justify-content-between">
                        <a href="index.html"><img src="/Logos-impulsa-4.png" alt=""/></a>
                        <div className="sidebar_close_icon d-lg-none">
                            <i className="ti-close"></i>
                        </div>
                    </div>
                    <ul id="sidebar_menu" className="metismenu">
                        {
                            localStorage.getItem('lasttoken') != 'null' ? 
                            <li onClick={()=>setAsImpulsa()}  >
                                <a href="#" title="Volver como perfil impulsa">
                                <i class="fas fa-user-lock"></i>
                                <span>
                                    Perfil Impulsa
                                </span>
                                </a>
                            </li> : null
                        }
                        <li className={current_menu == 'home' && 'mm-active'} >
                            <Link to="/home" onClick={()=> dispatch(setCurrentMenu('home'))}>
                            <i className="nav-icon fas fa-home"></i>
                            <span>
                                Inicio
                            </span>
                            </Link>
                        </li>
                        {
                            (userAuth && userAuth?.roles[0]?.name == 'impulsa') ?
                            <li className={current_menu == 'admin_accounts' && 'mm-active'}>
                                <Link to="/admin_accounts" onClick={()=> dispatch(setCurrentMenu('admin_accounts'))}>
                                <i className="nav-icon fas fa-users-cog"></i>
                                <span>
                                    Administración
                                </span>
                                </Link>
                            </li> : null

                            
                        }
                        {
                            (userAuth && userAuth.roles[0].name == 'administracion') ?
                            <li className={current_menu == 'users_list' && 'mm-active'}>
                                <Link to="/users_list" onClick={()=> dispatch(setCurrentMenu('users_list'))}>
                                <i className="nav-icon fas fa-users-cog"></i>
                                <span>
                                    Usuarios
                                </span>
                                </Link>
                            </li> : null
                        }
                        {
                            (userAuth && userAuth.roles[0].name != 'impulsa') ?
                            <li className={current_menu == 'clients' && 'mm-active'}>
                                <Link to="/clients" onClick={()=> dispatch(setCurrentMenu('clients'))}>
                                <i className="nav-icon fas fa-users"></i>
                                <span>
                                Clientes
                                </span>
                                </Link>
                            </li> 
                            
                            : null
                        }
                        
                        {
                            (userAuth && userAuth.currentPlan && userAuth.currentPlan.plan == 'Crece' && userAuth.roles[0].name != 'impulsa') ?
                            <li className={current_menu == 'contacts' && 'mm-active'}>
                                <Link to="/contacts" onClick={()=> dispatch(setCurrentMenu('contacts'))}>
                                <i className="nav-icon fas fa-user-alt"></i>
                                <span>
                                Contactos
                                </span>
                                </Link>
                            </li>:null
                        }
                        
                            
                            <li className={current_menu == 'mail_marketing' && 'mm-active'}>
                                <Link to="/mail_marketing" onClick={()=> dispatch(setCurrentMenu('mail_marketing'))}>
                                <i className="nav-icon fas fa-mail-bulk"></i>
                                <span>
                                Mail Marketing
                                </span>
                                </Link>
                            </li>
                        
                        
                            <li className={current_menu == 'landing_pages' && 'mm-active'}>
                                <Link to="/landing_pages" onClick={()=> dispatch(setCurrentMenu('landing_pages'))}>
                                <i className="nav-icon fas fa-plane-arrival"></i>
                                <span>
                                Landing Pages
                                </span>
                                </Link>
                            </li> 
                        {
                            (userAuth && userAuth.roles[0].name != 'impulsa') ?
                            <li className={current_menu == 'custom_fields' && 'mm-active'}>
                                {/* <Link to="/config" > */}
                                <Link to="/custom_fields" onClick={()=> dispatch(setCurrentMenu('custom_fields'))}>
                                <i className="nav-icon fas fa-cog"></i>
                                <span>
                                Configuración
                                </span>
                                </Link>
                            </li>:null
                        }
                        
                   
                        <li >
                            

                            <button >
                            <div className="image">
                                {
                                    userAuth ? 
                                    <img src={`${process.env.REACT_APP_BACKEND}/users/getImage/${userAuth.id}?${d.getTime()}`} className="img-circle elevation-2 left_circle_user" alt="User "/>
                                    : <img src={`${process.env.REACT_APP_USER_IMAGE}`} className="img-circle elevation-2 left_circle_user" alt="User "/>
                                }
                            </div>
                            <div class="btn-group">
                                <button type="button" class="btn btn-danger dropdown-toggle eliminar_margin_left" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="caret"></span>
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <ul class="dropdown-menu">
                                    <li><Link to="/profile" className="cerrar_session" title="Ver Perfil"><i className="nav-icon fas fa-user" style={{padding:"10px"}}></i>Mi Perfil</Link></li>
                                    <li><a href="#" className="cerrar_session" onClick={ handleLogout } ><i className="nav-icon fas fa-sign-out-alt" style={{padding:"10px"}}></i>Cerrar Sesión</a></li>
                                </ul>
                            </div>
                            </button>
                        </li>
                    </ul>

                </nav>
         
    )
}
