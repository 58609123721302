import {
    CREATE_SCHELUDE_CAMPPAIGN,
    DELETE_RESPONSE
}
    from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';

export function create_scheduledDate_action(send_time, campaign_id, timezone) {
    return async (dispatch) => {
        try {
            const userLocalTime = moment.tz(send_time, 'YYYY-MM-DDTHH:mm', timezone);
            const sendTimeUTC = userLocalTime.utc().toISOString();

            const respuesta = await clienteAxios.post(`/schedule_campaign/create`, { send_time: sendTimeUTC, campaign_id });
            // console.log('respuesta create_scheduledDate_action', respuesta.data)
            dispatch(create_scheduledDate(respuesta.data))
            Swal.fire({ title: "Éxito", text: 'Se ha programado el envio correctamente', icon: "success" })
        } catch (error) {
            // console.log(`error create_scheduledDate_action: ${error}`)
            // console.log('error.response',error?.response?.data?.message)
            if (error?.response?.data?.message) {
                Swal.fire({
                    icon: 'error',
                    title: 'Hubo un error',
                    text: error?.response?.data?.message
                })
            } else {

                Swal.fire({
                    icon: 'error',
                    title: 'Hubo un error',
                    text: 'Hubo un error, intenta de nuevo'
                })
            }

        }

    }
}

const create_scheduledDate = (scheduledDate) => ({
    type: CREATE_SCHELUDE_CAMPPAIGN,
    payload: scheduledDate
})

export function eliminarProgramaAction(id) {
    return async (dispatch) => {
        try {
            const respuesta = await clienteAxios.delete(`/schedule_campaign/delete/${id}`);
            // console.log('respuesta create_scheduledDate_action', respuesta)
            dispatch(delete_response(respuesta.data))
        } catch (error) {
            console.log(error)

            Swal.fire({
                icon: 'error',
                title: 'Hubo un error',
                text: 'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const delete_response = (data) => ({
    type: DELETE_RESPONSE,
    payload: data
})

